var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "预存款单详情",
            width: "70%",
            center: "",
            visible: _vm.outerVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.outerVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "postre" },
            [
              _c("div", { staticClass: "aree" }, [
                _c("span", [_vm._v(_vm._s(_vm.form.check_status_text))]),
              ]),
              _c(
                "div",
                { staticClass: "top" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单号：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.bill_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bill_code", $$v)
                              },
                              expression: "form.bill_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "签单：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.create_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "create_at", $$v)
                              },
                              expression: "form.create_at",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.cust_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_name", $$v)
                              },
                              expression: "form.cust_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.boss,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "boss", $$v)
                              },
                              expression: "form.boss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动名称：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.staff_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "staff_name", $$v)
                              },
                              expression: "form.staff_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "签单金额：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.total_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "total_amount", $$v)
                              },
                              expression: "form.total_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "已收款：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.receive_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "receive_amount", $$v)
                              },
                              expression: "form.receive_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "赠送金额：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.gift_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "gift_amount", $$v)
                              },
                              expression: "form.gift_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "已下单：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.already_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "already_amount", $$v)
                              },
                              expression: "form.already_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "可用余额：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.form.left_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "left_amount", $$v)
                              },
                              expression: "form.left_amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "120px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          size: "mini",
                          type: "textarea",
                          rows: 2,
                          disabled: "",
                        },
                        model: {
                          value: _vm.form.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "note", $$v)
                          },
                          expression: "form.note",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预存方案：" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "60%" },
                          attrs: { stripe: "", data: _vm.form.sche_list },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "预存金额",
                              width: "auto",
                              popr: "total_amount",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " + _vm._s(row.total_amount) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "赠送金额",
                              width: "auto",
                              popr: "gift_amount",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(" " + _vm._s(row.gift_amount) + " "),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "协议赠品",
                              width: "auto",
                              popr: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "underline",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlergive(row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.give_list.length) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "限定商品",
                              width: "auto",
                              popr: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlergoodslik(row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              row.goods_list_front.length
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: !_vm.isEdit,
                          "active-value": "1",
                          "inactive-value": "0",
                          "active-text": "下单时业务员可修改价格",
                        },
                        model: {
                          value: _vm.form.price_status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "price_status", $$v)
                          },
                          expression: "form.price_status",
                        },
                      }),
                      _c("br"),
                      _c("el-switch", {
                        attrs: {
                          disabled: !_vm.isEdit,
                          "active-value": "1",
                          "inactive-value": "0",
                          "active-text":
                            "允许超额下单:勾选后，单据可用金额可以是负数，客户可超出已付款下单，超出部分计入待收款金额",
                        },
                        model: {
                          value: _vm.form.exceed_status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "exceed_status", $$v)
                          },
                          expression: "form.exceed_status",
                        },
                      }),
                      _c("br"),
                      _c("br"),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "赠品赠送条件：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.gift_goods_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "gift_goods_type", $$v)
                            },
                            expression: "form.gift_goods_type",
                          },
                        },
                        _vm._l(_vm.citiesing, function (city) {
                          return _c(
                            "el-radio",
                            {
                              key: city.id,
                              attrs: { disabled: !_vm.isEdit, label: city.id },
                            },
                            [_vm._v(" " + _vm._s(city.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单记录：" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "60%" },
                          attrs: {
                            stripe: "",
                            "summary-method": _vm.getSummaries,
                            data: _vm.form.log_list,
                            height: "200px",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "单号",
                              align: "center",
                              width: "auto",
                              prop: "sale_order_code",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.sale_order_code.indexOf("合计") == -1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "under-line",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleCheckDetail(
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.sale_order_code) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(row.sale_order_code) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "使用金额",
                              align: "center",
                              width: "auto",
                              prop: "total_amount",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "时间",
                              align: "center",
                              width: "auto",
                              prop: "create_at",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收款记录：" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "60%" },
                          attrs: {
                            stripe: "",
                            "summary-method": _vm.getSummaries,
                            data: _vm.form.pay_list,
                            height: "200px",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "收款人",
                              align: "center",
                              width: "auto",
                              prop: "user_name",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "时间",
                              align: "center",
                              width: "auto",
                              prop: "create_at",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "收款方式",
                              align: "center",
                              width: "auto",
                              prop: "type",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "金额",
                              align: "center",
                              width: "auto",
                              prop: "amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                modal: false,
                width: "30%",
                title: "确定要终止本协议吗？",
                visible: _vm.innerVisible,
                center: "",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.innerVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, "label-width": "120px" },
                    },
                    [
                      _c("el-form-item", { attrs: { label: "签单金额：" } }, [
                        _vm._v(" " + _vm._s(_vm.form.total_amount) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "累计金额：" } }, [
                        _vm._v(" " + _vm._s(_vm.form.receive_amount) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "已下单：" } }, [
                        _vm._v(" " + _vm._s(_vm.form.already_amount) + " "),
                      ]),
                      _c("el-form-item", { attrs: { label: "可退款：" } }, [
                        _vm._v(" " + _vm._s(_vm.form.back_amount) + " "),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "退款：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { max: _vm.form.back_amount },
                            model: {
                              value: _vm.tuikuan,
                              callback: function ($$v) {
                                _vm.tuikuan = $$v
                              },
                              expression: "tuikuan",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "退款方式:" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              model: {
                                value: _vm.form.pay_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pay_type", $$v)
                                },
                                expression: "form.pay_type",
                              },
                            },
                            _vm._l(
                              _vm.allSelectList.arr_pay_type,
                              function (list) {
                                return _c("el-option", {
                                  key: list.id,
                                  attrs: { value: list.id, label: list.name },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlertukuan },
                    },
                    [_vm._v(" 终止并退款 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.innerVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.form.check_status != 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.innerVisible = !_vm.innerVisible
                        },
                      },
                    },
                    [_vm._v(" 终止协议 ")]
                  )
                : _vm._e(),
              _vm.isEdit
                ? _c("el-button", { on: { click: _vm.handleEdit } }, [
                    _vm._v("修改"),
                  ])
                : _vm._e(),
              _c("el-button", { on: { click: _vm.handlePrint } }, [
                _vm._v("打 印"),
              ]),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.outerVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("collection-superclass", { ref: "superclass" }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "YK", data_id: _vm.data_id, type: 1 },
      }),
      _c("advance-order", { ref: "advanceOrder" }),
      _c("sale-order", { ref: "saleOrder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }