<!--
 * @Author: 候怀烨
 * @Date: 2020-12-24 10:23:32
 * @LastEditTime: 2021-04-07 16:38:24
 * @LastEditors: Please set LastEditors
 * @Description: 查看单据详情详情
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositreceipt\components\components\likeDetails.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      title="预存款单详情"
      width="70%"
      center
      :visible.sync="outerVisible"
    >
      <div class="postre">
        <div class="aree">
          <span>{{ form.check_status_text }}</span>
        </div>
        <div class="top">
          <!-- form表单 -->
          <el-form ref="form" :model="form" inline label-width="100px">
            <el-form-item label="单号：">
              <el-input
                v-model="form.bill_code"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="签单：">
              <el-input
                v-model="form.create_at"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="客户名称：">
              <el-input
                v-model="form.cust_name"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人：">
              <el-input
                v-model="form.boss"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="地址：">
              <el-input
                v-model="form.address"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="活动名称：">
              <el-input
                v-model="form.name"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="业务员：">
              <el-input
                v-model="form.staff_name"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="签单金额：">
              <el-input
                v-model="form.total_amount"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="已收款：">
              <el-input
                v-model="form.receive_amount"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="赠送金额：">
              <el-input
                v-model="form.gift_amount"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="已下单：">
              <el-input
                v-model="form.already_amount"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="可用余额：">
              <el-input
                v-model="form.left_amount"
                style="width: 150px"
                size="mini"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="备注：">
            <el-input
              v-model="form.note"
              size="mini"
              type="textarea"
              :rows="2"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <!-- 预存方案table -->
          <el-form-item label="预存方案：">
            <el-table stripe :data="form.sche_list" style="width: 60%">
              <el-table-column
                align="center"
                label="预存金额"
                width="auto"
                popr="total_amount"
              >
                <template #default="{ row }">
                  {{ row.total_amount }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="赠送金额"
                width="auto"
                popr="gift_amount"
              >
                <template #default="{ row }">
                  {{ row.gift_amount }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="协议赠品"
                width="auto"
                popr=""
              >
                <template #default="{ row }">
                  <span class="underline" @click="handlergive(row)">
                    {{ row.give_list.length }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="限定商品"
                width="auto"
                popr=""
              >
                <template #default="{ row }">
                  <span @click="handlergoodslik(row)">
                    {{ row.goods_list_front.length }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <!-- 多选 -->
          <el-form-item>
            <el-switch
              v-model="form.price_status"
              :disabled="!isEdit"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="下单时业务员可修改价格"
            ></el-switch>

            <br />
            <el-switch
              v-model="form.exceed_status"
              :disabled="!isEdit"
              :active-value="'1'"
              :inactive-value="'0'"
              active-text="允许超额下单:勾选后，单据可用金额可以是负数，客户可超出已付款下单，超出部分计入待收款金额"
            ></el-switch>

            <br />
            <!-- <el-switch
              v-model="form.gift_status"
              disabled
              active-text="允许使用预存款购买常规赠品"
              :active-value="'1'"
              :inactive-value="'0'"
            ></el-switch> -->

            <br />
          </el-form-item>
          <!-- 单选 -->
          <el-form-item label="赠品赠送条件：">
            <el-radio-group v-model="form.gift_goods_type">
              <el-radio
                v-for="city in citiesing"
                :key="city.id"
                :disabled="!isEdit"
                :label="city.id"
              >
                {{ city.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 下单记录表格table -->
          <el-form-item label="下单记录：">
            <el-table
              stripe
              :summary-method="getSummaries"
              :data="form.log_list"
              style="width: 60%"
              height="200px"
            >
              <el-table-column
                label="单号"
                align="center"
                width="auto"
                prop="sale_order_code"
              >
                <template #default="{ row }">
                  <span
                    v-if="row.sale_order_code.indexOf('合计') == -1"
                    class="under-line"
                    @click="handleCheckDetail(row)"
                  >
                    {{ row.sale_order_code }}
                  </span>
                  <span v-else>
                    {{ row.sale_order_code }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="使用金额"
                align="center"
                width="auto"
                prop="total_amount"
              ></el-table-column>
              <el-table-column
                label="时间"
                align="center"
                width="auto"
                prop="create_at"
              ></el-table-column>
            </el-table>
          </el-form-item>
          <!-- 收款记录表格table -->
          <el-form-item label="收款记录：">
            <el-table
              stripe
              :summary-method="getSummaries"
              :data="form.pay_list"
              style="width: 60%"
              height="200px"
            >
              <el-table-column
                label="收款人"
                align="center"
                width="auto"
                prop="user_name"
              ></el-table-column>
              <el-table-column
                label="时间"
                align="center"
                width="auto"
                prop="create_at"
              ></el-table-column>
              <el-table-column
                label="收款方式"
                align="center"
                width="auto"
                prop="type"
              ></el-table-column>
              <el-table-column
                label="金额"
                align="center"
                width="auto"
                prop="amount"
              ></el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>

      <el-dialog
        :modal="false"
        width="30%"
        title="确定要终止本协议吗？"
        :visible.sync="innerVisible"
        center
        append-to-body
      >
        <div>
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="签单金额：">
              {{ form.total_amount }}
              <!-- <el-input
                v-model="form.total_amount"
                style="width: 200px"
                disabled
              ></el-input> -->
            </el-form-item>
            <el-form-item label="累计金额：">
              {{ form.receive_amount }}
              <!-- <el-input
                v-model="form.receive_amount"
                style="width: 200px"
                disabled
              ></el-input> -->
            </el-form-item>
            <el-form-item label="已下单：">
              {{ form.already_amount }}
              <!-- <el-input
                v-model="form.already_amount"
                style="width: 200px"
                disabled
              ></el-input> -->
            </el-form-item>
            <el-form-item label="可退款：">
              {{ form.back_amount }}
              <!-- <el-input
                v-model="form.back_amount"
                style="width: 200px"
                disabled
              ></el-input> -->
            </el-form-item>
            <el-form-item label="退款：">
              <el-input
                v-model="tuikuan"
                style="width: 200px"
                :max="form.back_amount"
              ></el-input>
            </el-form-item>
            <el-form-item label="退款方式:">
              <el-select v-model="form.pay_type" style="width: 200px">
                <el-option
                  v-for="list in allSelectList.arr_pay_type"
                  :key="list.id"
                  :value="list.id"
                  :label="list.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handlertukuan">
            终止并退款
          </el-button>
          <!-- <el-button type="warning" @click="handlerzhongzhi">仅终止</el-button> -->
          <el-button @click="innerVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-if="form.check_status != 2"
          type="primary"
          @click="innerVisible = !innerVisible"
        >
          终止协议
        </el-button>
        <el-button v-if="isEdit" @click="handleEdit">修改</el-button>
        <el-button @click="handlePrint">打 印</el-button>
        <el-button @click="outerVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog :modal="false" title="确定要终止本协议吗？" center :visible.sync="zhognzhi">
      <div></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary">终止并退款</el-button>
        <el-button type="warning">仅终止</el-button>
        <el-button>取消</el-button>
      </div>
    </el-dialog> -->
    <collection-superclass ref="superclass"></collection-superclass>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="YK"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <advance-order ref="advanceOrder"></advance-order>
    <sale-order ref="saleOrder"></sale-order>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { getAllSelect } from '@/api/advanceOrder'
  // import index from '@/baseComponents/baseTable/index.vue'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import saleOrder from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  export default {
    // components: { index },
    components: {
      CollectionSuperclass: () => import('../CollectionSuperclass.vue'),
      RuilangDisplayDialog,
      advanceOrder,
      saleOrder,
    },
    data() {
      return {
        tuikuan: '',
        allSelectList: {},
        data_id: '',
        userlist: [],
        isEdit: false,
        userid: '',
        orderid: '',
        form: {
          pay_type: '',
        },
        tableData: [],
        outerVisible: false,
        innerVisible: false,
        checkedCities: [],
        citiesing: [
          { name: '签约即送', id: '1' },
          { name: '全额收款后赠送', id: '2' },
          { name: '消费完后赠品', id: '3' },
        ],
        cities: [
          {
            id: 1,
            name: '下单时业务员可修改价格',
          },
          {
            id: 2,
            name: '允许超额下单：客户可超出预存款单据余额下单，超出部分计入应收欠款',
          },
          {
            id: 3,
            name: '允许使用预存款购买常规赠品',
          },
        ],
        url: {
          Data: '/promoteAdmin/deposit-order/detail',
          stop: '/promoteAdmin/deposit-order/stop',
        },
      }
    },
    watch: {
      innerVisible(e) {
        if (!e) {
          this.tuikuan = ''
          this.userid = ''
        } else {
          let a = this.allSelectList.arr_pay_type[0].id
          console.log('aaaa', a)
          // this.form.pay_type = a
          this.$set(this.form, 'pay_type', a)
          this.tuikuan = JSON.parse(JSON.stringify(this.form.back_amount))
        }
      },
    },
    mounted() {
      this.handleruserlist()
      this.getAllSelectList()
    },
    methods: {
      async getAllSelectList() {
        let { data, code, msg } = await getAllSelect()
        if (code == 200) {
          console.log('所有下拉列表2')
          console.log(data)
          this.allSelectList = data
        }
      },
      handlergive(row) {
        console.log(row)
        this.$refs.superclass.zp = true
        if (row.give_list.length > 0) {
          var goodsdata = []
          goodsdata = row.give_list.map((list) => {
            return {
              goods_price: list.goods_price,
              unit_name: list.unit_list.filter(
                (item) => item.unit_id == list.unit_id
              )[0].unit_name,
              goods_name: list.goods_name,
              goods_specs: list.goods_specs,
              goods_num: list.goods_num,
            }
          })
        }
        this.$refs.superclass.zpdata = goodsdata
        console.log(this.$refs.superclass.zpdata, '赠品数据')
      },
      handlergoodslik(row) {
        console.log(row)
        this.$refs.superclass.sp = true
        this.$refs.superclass.treelist = row.brand_list
        // if (row.goods_list.length > 0) {
        //   var goodsdata = []
        //   goodsdata = row.goods_list.map((list) => {
        //     return {
        //       goods_price: list.unit_list[0].goods_price,
        //       unit_name: list.unit_list[0].unit_name,
        //       goods_name: list.goods_name,
        //       goods_specs: list.goods_specs,
        //     }
        //   })
        // }
        // this.$refs.superclass.spdata = goodsdata
        this.$refs.superclass.spdata = row.goods_list_front
      },
      //业务员
      handleruserlist() {
        postAction('/baseAdmin/common/staff-option', {})
          .then((res) => {
            console.log(res, '')
            this.userlist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //终止协议
      handlertukuan() {
        postAction(this.url.stop, {
          id: this.orderid,
          money: this.tuikuan,
          user_id: this.userid,
          pay_type: this.form.pay_type,
        })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.outerVisible = false
            this.innerVisible = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerzhongzhi() {
        postAction(this.url.stop, {
          id: this.orderid,
          money: 0,
          user_id: this.userid,
        })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.outerVisible = false
            this.innerVisible = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //预存款单详情数据
      handlerData(id) {
        this.orderid = id
        postAction(this.url.Data, { id: id })
          .then((res) => {
            console.log(res, '成功')
            this.form = res.data
            if (res.data.price_status == 1) {
              this.checkedCities.push('1')
            }
            if (res.data.exceed_status == 1) {
              this.checkedCities.push('2')
            }
            if (res.data.gift_status == 1) {
              this.checkedCities.push('3')
            }
          })
          .catch((err) => {
            console.log(err, '失败')
          })
      },
      handleCheckedCitiesChange() {},
      //合计
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价'
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 元'
          } else {
            sums[index] = 'N/A'
          }
        })

        return sums
      },
      handlePrint() {
        this.$refs['RuilangDisplayDialog'].batch = false
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
      handleEdit() {
        postAction('/promoteAdmin/deposit-order/order-update', {
          ...this.form,
          ...{ id: this.data_id },
        }).then((r) => {
          this.$emit('getlist')
          this.outerVisible = false
          this.$message.success('修改成功')
        })
      },
      // 查看订单详情
      handleCheckDetail(row) {
        console.log(row)
        if (row.order_type == 2) {
          //XS 销售单 order_id
          this.$refs['saleOrder'].isshowDialog = true
          // this.$refs['saleOrder'].orderStatus = row.bill_status_text
          this.$refs['saleOrder'].id = row.order_id
        } else if (row.order_type == 1) {
          // XD 预订单 order_id
          this.$refs['advanceOrder'].showDialog()
          this.$refs['advanceOrder'].orderStatus2 = row.bill_status_text
          // this.$refs['advanceOrder'].orderStatus = row.bill_status_text
          this.$refs['advanceOrder'].id = row.order_id
        }
      },
    },
  }
</script>

<style>
  .flex {
    display: flex;
    flex-direction: column;
  }
  .postre {
    position: relative;
  }
  .aree {
    position: absolute;
    top: -50px;
    right: 40px;
    z-index: 30;
    padding: 30px 18px;
    color: #f3b406;
    border: #f3b406 2px solid;
    border-radius: 100px;
  }
</style>
